<template>
  <nav class="shadow bg-white flex justify-between" v-if="session">
    <div>
      <router-link to="/">
        <div class="inline-flex w-max ">
            <img src="https://tailus.io/images/logo.svg" class="w-32" alt="">
        </div>
      </router-link>
    </div>
    <div class="inline-flex space-x-3">
      <router-link class="mb-2" to="/about">
        <span class="inline-flex px-4 py-1 text-sm text-blue-400 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-blue-400 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2">
          <svg height="30px" width="25px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
          </svg>
          ¡Apóyanos!
        </span>
      </router-link>
      <button class="inline-flex">
        <svg  height="30px" width="25px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-1 h-1">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
        </svg>
      </button>
      <button class="inline-flex">
        <svg height="30px" width="25px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"   strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
        </svg>
      </button>
      <DropdownUser/>

    </div>
  </nav>
  <!-- {{session}} -->

  <div class="rejilla">
    <div class="sidemain z-10">
      <Sidebar v-if="session"/>
    </div>
    <div class="z-0">
      <router-view/>
    </div>

  </div>
  
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.rejilla {
  display: grid;
  grid-template-columns: 5% 1fr;
}

</style>

<script>
  import {mapState} from 'vuex';
  import DropdownUser from  '@/components/DropdownUser'
  import Sidebar from  '@/components/Sidebar'
  export default {
    components:{
      DropdownUser,
      Sidebar
    },
    computed: {
      ...mapState('login', ['session'])
    }
  }
</script>
