<template>

    <div class="min-h-screen bg-gray-100">
        <div class="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-60 hover:bg-gray-50 hover:shadow-lg">
            <div class="flex h-screen flex-col justify-between pt-2 pb-6">
            <div>
                <!-- <div class="w-max p-2.5">
                    <img src="https://tailus.io/images/logo.svg" class="w-32" alt="">
                </div> -->
                <ul class="mt-6 space-y-2 tracking-wide">
                    <li class="min-w-max">
                        <router-link to="/home" aria-label="dashboard" class="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-cyan-400 px-4 py-3 text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  stroke="currentColor"  class="w-5 h-5" className="w-6 h-6">
                                <path class="fill-current text-gray-600 group-hover:text-cyan-300" strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>

                            <span class="-mr-1 font-medium">Inicio</span>
                        </router-link>
                    </li>
                    <li class="min-w-max">
                        <router-link to="/cronogramas" class="group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                            <span><img src="@/assets/cronograma.png" alt="" srcset="" width="30" height="50"></span>
                            <span class="group-hover:text-gray-700">Cronograma</span>
                        </router-link>
                    </li>
                    <li class="min-w-max">
                        <router-link to="postular-empresas" class="group flex items-center space-x-4 rounded-full px-4 py-3 text-gray-600">
                           <span><img src="@/assets/company2.png" alt="" srcset="" width="30" height="50"></span>
                            <span class="group-hover:text-gray-700">Postular a Empresas</span>
                        </router-link>
                    </li>
                    <li class="min-w-max">
                        <router-link to="carta-de-postulacion" class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                           <span><img src="@/assets/file.png" alt="" srcset="" width="30" height="50"></span>
                            <span class="group-hover:text-gray-700">Carta de postulacion</span>
                        </router-link>
                    </li>
                    <li class="min-w-max">
                         <router-link to="archivos-de-inscripcion" class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                            <span><img src="@/assets/inscripcion.png" alt="" srcset="" width="30" height="50"></span>

                            <span class="group-hover:text-gray-700">Archivos de Inscripcion</span>
                        </router-link>
                    </li>
                    <li class="min-w-max">
                        <router-link to="asignar-tutor" class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                            <span><img src="@/assets/tutor.png" alt="" srcset="" width="35" height="50"></span>
                            <span class="group-hover:text-gray-700">Asignar Tutor</span>
                        </router-link>
                    </li>

                    <li class="min-w-max">
                       <router-link to="evaluar-estudiante" class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                            <span><img src="@/assets/evaluar.png" alt="" srcset="" width="25" height="50"></span>
                            <span class="group-hover:text-gray-700">Evaluar estudiante</span>
                        </router-link>
                    </li>

                     <li class="min-w-max">
                        <router-link to="estudiantes" class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                            <span><img src="@/assets/students.png" alt="" srcset="" width="25" height="50"></span>
                            <span class="group-hover:text-gray-700">Estudiantes</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="w-max -mb-3">
                <router-link to="usuario"  class="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 group-hover:fill-cyan-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 group-hover:fill-cyan-600"  height="1em" viewBox="0 0 448 512">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/>
                    </svg>
                    <span class="group-hover:text-gray-700">Usuarios</span>
                </router-link>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'Sidebar',
    };
</script>

<style scoped>

</style>

