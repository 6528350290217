export default {
    namespaced: true,
    state: {
        session: false
    },
    mutations: {
        setSession(state, value){
            state.session = value;
        }
    },
    actions: {
       
    },
    getters:{

    }

}