import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign-In',
    name: 'sign-in',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/asignar-tutor',
    name: 'asignar-tutor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AssignTutor.vue')
  },
  {
    path: '/postular-empresas',
    name: 'postular-empresas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Companies.vue')
  },
  {
    path: '/evaluar-estudiante',
    name: 'evaluar-estudiante',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/EvaluateStudent.vue')
  },
  {
    path: '/archivos-de-inscripcion',
    name: 'archivos-de-inscripcion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InscriptionsFiles.vue')
  },
  {
    path: '/carta-de-postulacion',
    name: 'carta-de-postulacion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/PostulationLetter.vue')
  },
  {
    path: '/cronogramas',
    name: 'cronogramas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Schedule.vue')
  },
  {
    path: '/estudiantes',
    name: 'estudiantes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Students.vue')
  },
  {
    path: '/usuario',
    name: 'usuario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/User.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
